import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'
// import router from 'vue-router'

const apiUrl = {
  local: 'http://127.0.0.1:8001/api',
  local2: 'http://api-admin.in/api',
  dev: 'https://api.newadmin.olimp.work/api',
  prod: {
    bet: 'https://api.admin3.olimp.work/api',
    com: 'https://api.admin2.ragnaros.net/api',
    banners: 'https://api.admin.betolimpsa.site/api',
  },
  env: process.env.VUE_APP_API_URL,
}
const apiClient = axios.create({
  baseURL: apiUrl.env,
  // timeout: 1000,
  // headers: { 'Access-Control-Allow-Origin': '*' },
  headers: { Localization: 'en' },
  withCredentials: false,
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  const expiresAt = store.get('expiresAt')
  const lastServerTime = store.get('lastServerTime')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  } else {
    const name = 'accessToken'
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) {
      const sessionToken = match[2]
      request.headers.Authorization = `Bearer ${sessionToken}`
    }
  }
  if (expiresAt) {
    // console.log('expires at', expiresAt)
    if (lastServerTime && accessToken) {
      const remTime = expiresAt - lastServerTime
      // console.log('remaining session time', remTime)
      if (remTime < 21593) {
        // store.remove('accessToken')
        // store.dispatch('user/LOGOUT')
        // store.dispatch('user/REFRESH', { payload: { remember: true } })
        // router.push('/auth/login')
      }
    }
  }
  return request
})

apiClient.interceptors.response.use(response => {
  // console.log('response headers', response.headers)
  const serverTime = response.headers.date
  const expiresAt = store.get('expiresAt')
  const lastServerTime = store.get('lastServerTime')
  if (serverTime) {
    store.set('lastServerTime', Date.parse(serverTime) / 1000)
    // console.log('server time', Date.parse(serverTime) / 1000)
  }
  if (response.data.error) {
    // Error message is retrived from the JSON body.
    const error = response.data.error.message ? new Error(response.data.error.message) : new Error(response.data.error)
    // Attach the response instance, in case we would like to access it.
    error.response = response
    if (typeof (response.data.noauth) !== 'undefined') {
      console.log('redirect to LOGOUT')
      if (expiresAt && lastServerTime) {
        const remTime = expiresAt - lastServerTime
        console.log('session remaining time', remTime)
      }
      const accessToken = store.get('accessToken')
      if (accessToken) {
        store.remove('accessToken')
        sessionStorage.removeItem('app.encryption.privatekey')
        location.reload()
      }
      // store.dispatch('user/LOGOUT')
      // router.push('/auth/login')
    }
    throw error
  }
  // If the status is true, simply return back the response instance.
  return response
}, error => {
  // Errors handling
  const { response } = error
  // console.log(response)
  const { data } = response
  if ((data.error) && (!data.error.message)) {
    notification.warning({
      message: data.error,
    })
  }
})

export default apiClient
