import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
// import store from '@/store'

Vue.use(Router)

const router = new Router({
  // base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/promo-images', // 'dashboard/alpha',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
        breadCrumb: 'Home',
      },
      breadcrumbName: 'Home',
      children: [
        {
          path: '/database',
          meta: {
            title: 'Database',
            crumbName: 'Database',
          },
          component: () => import('./views/apps/database'),
        },
        {
          path: '/stories',
          meta: {
            title: 'Stories',
          },
          breadcrumbName: 'Stories',
          component: () => import('./views/apps/stories'),
        },
        {
          path: '/promo-images',
          meta: {
            title: 'Promo images',
          },
          breadcrumbName: 'Promo images',
          component: () => import('./views/apps/promo-images'),
          children: [
            {
              name: 'promo-images',
              path: '/',
              meta: {
                title: 'Promo images',
                breadCrumb: 'Promo images',
              },
              breadcrumbName: 'Promo images',
              component: () => import('./views/apps/promo-images/promoList'),
            },
            {
              name: 'promoPage',
              path: 'create-promo',
              meta: {
                title: 'Promo image',
              },
              breadcrumbName: 'Create promo image',
              component: () => import('./views/apps/promo-images/promoForm'),
            },
            {
              name: 'editPromo',
              path: ':type/:id',
              meta: {
                title: 'Promo image',
              },
              breadcrumbName: 'Edit promo image',
              component: () => import('./views/apps/promo-images/promoForm'),
            },
          ],
        },
        {
          path: '/log/models',
          meta: {
            title: 'Model change logs',
            breadCrumb: 'model logs',
          },
          breadcrumbName: 'Model change logs',
          component: () => import('./views/apps/logging/models'),
        },
        {
          path: '/splashscreens',
          meta: {
            title: 'Splashscreens',
          },
          breadcrumbName: 'Splashscreens',
          component: () => import('./views/apps/splashscreens'),
        },
        {
          path: '/main',
          meta: {
            title: 'Main',
          },
          breadcrumbName: 'Main',
          component: () => import('./views/apps/main'),
        },
        {
          path: '/top-matches',
          meta: {
            title: 'Top matches',
          },
          breadcrumbName: 'Top matches',
          component: () => import('./views/apps/top'),
        },
        {
          path: '/events',
          meta: {
            title: 'events',
            breadCrumb: 'Events',
          },
          breadcrumbName: 'events',
          component: () => import('./views/apps/events'),
          children: [
            {
              name: 'events',
              path: '/',
              meta: {
                title: 'events',
                breadCrumb: 'Events',
              },
              breadcrumbName: 'events',
              component: () => import('./views/apps/events/events'),
            },
            {
              name: 'event',
              path: ':event',
              props: true,
              meta: {
                title: 'event',
                breadCrumb: 'event',
              },
              breadcrumbName: 'event',
              component: () => import('./views/apps/events/event'),
            },
            {
              name: 'page',
              path: ':event/page-:page',
              props: true,
              meta: {
                title: 'page',
                breadCrumb: 'Event',
              },
              breadcrumbName: 'Page',
              component: () => import('./views/apps/events/page'),
            },
          ],
        },
        {
          path: '/affiliates',
          meta: {
            title: 'affiliates',
            breadCrumb: 'Affilates',
          },
          breadcrumbName: 'affiliates',
          component: () => import('./views/apps/affiliates'),
          children: [
            {
              path: '/',
              meta: {
                title: 'affilates',
                breadCrumb: 'Affiliates',
              },
              breadcrumbName: 'affiliates',
              component: () => import('./views/apps/affiliates/codes'),
            },
            {
              name: 'users',
              path: ':code',
              props: true,
              meta: {
                title: 'users',
                breadCrumb: 'users',
              },
              breadcrumbName: 'users',
              component: () => import('./views/apps/affiliates/users'),
            },
            // {
            //   name: 'page',
            //   path: ':event/page-:page',
            //   props: true,
            //   meta: {
            //     title: 'page',
            //     breadCrumb: 'Event',
            //   },
            //   breadcrumbName: 'Page',
            //   component: () => import('./views/apps/events/page'),
            // },
          ],
        },
        {
          path: '/push-notifications',
          meta: {
            title: 'Push-notifications',
          },
          component: () => import('./views/apps/push-notifications'),
        },
        {
          path: '/push-notifications/add-push',
          meta: {
            title: 'Add Push-notification',
          },
          component: () => import('./views/apps/push-notifications/addPush.vue'),
        },
        {
          path: '/accounts',
          meta: {
            title: 'Accounts',
          },
          component: () => import('./views/apps/users'),
        },
        // {
        //   path: '/users',
        //   meta: {
        //     title: 'Users',
        //   },
        //   component: () => import('./views/apps/accounts'),
        // },
        //
        {
          path: '/profiles',
          meta: {
            title: 'Profiles',
            crumbName: 'Profiles',
          },
          component: () => import('./views/apps/profiles'),
          children: [
            {
              path: '/',
              redirect: 'users',
            },
            {
              path: 'users',
              meta: {
                title: 'Users',
              },
              component: () => import('./views/apps/profiles/users'),
            },
            {
              path: 'whitelist',
              meta: {
                title: 'Whitelist',
              },
              component: () => import('./views/apps/profiles/whitelist'),
            },
          ],
        },
        //
        {
          path: '/refferal_promocodes',
          meta: {
            title: 'Refferal promocodes',
          },
          component: () => import('./views/apps/refferal_promocodes'),
        },
        {
          path: '/analytics',
          meta: {
            title: 'Analytics',
          },
          component: () => import('./views/apps/analytics'),
        },
        {
          path: '/updates',
          meta: {
            title: 'Updates',
            crumbName: 'Updates',
          },
          component: () => import('./views/apps/updates'),
        },
        {
          path: '/settings',
          meta: {
            title: 'Settings',
            breadCrumb: 'Settings',
          },
          component: () => import('./views/settings'),
          children: [
            {
              path: '/',
              redirect: 'apps',
            },
            {
              path: 'apps',
              meta: {
                title: 'Apps',
              },
              component: () => import('./views/settings/components/apps.vue'),
            },
            {
              path: 'deeplinks',
              meta: {
                title: 'Deeplinks',
              },
              component: () => import('./views/settings/components/deeplinks.vue'),
            },
            {
              path: 'firebase-keys',
              meta: {
                title: 'firebase-keys',
              },
              component: () => import('./views/settings/components/firebase-keys.vue'),
            },
            {
              path: 'languages',
              meta: {
                title: 'Languages',
              },
              component: () => import('./views/settings/components/languages.vue'),
            },
          ],
        },
        {
          path: '/permissions',
          meta: {
            title: 'Roles & permissions',
          },
          component: () => import('./views/permissions'),
        },
        {
          path: '/cloud-store',
          meta: {
            title: 'Cloud store',
            crumbName: 'Cloud store',
          },
          component: () => import('./views/cloud-store'),
        },
        {
          path: '/subscriptions/matches',
          meta: {
            title: 'Match events subscriptions',
            crumbName: 'Match events subscriptions',
          },
          component: () => import('./views/apps/subscriptions/matches'),
        },
        {
          path: '/reviews',
          meta: {
            title: 'Reviews',
            crumbName: 'Reviews',
          },
          component: () => import('./views/feedbacks'),
        },
        {
          path: '/promocode',
          meta: {
            title: 'Promocode',
            crumbName: 'Promocode',
          },
          component: () => import('./views/apps/promocode'),
        },
        {
          path: '/sports',
          meta: {
            title: 'Icons & Colors',
            crumbName: 'Icons & Colors',
          },
          component: () => import('./views/apps/sports/colors'),
        },
        {
          path: '/gifs',
          meta: {
            title: 'Gifs',
            crumbName: 'Gifs',
          },
          component: () => import('./views/apps/gifs'),
        },
        {
          path: '/encryption',
          meta: {
            title: 'Encryption',
            crumbName: 'Encryption',
          },
          component: () => import('./views/apps/encryption'),
        },
        {
          path: '/backup',
          meta: {
            title: 'Backup',
            crumbName: 'Backup',
          },
          component: () => import('./views/apps/server/backups'),
        },
        {
          path: '/betatests',
          meta: {
            title: 'Betatests',
            crumbName: 'Betatests',
          },
          component: () => import('./views/betatests/users'),
        },
        {
          path: '/emergency-mode',
          meta: {
            title: 'Emergency mode',
            crumbName: 'Emergency mode',
          },
          component: () => import('./views/apps/emergency/index.vue'),
        },
        // {
        //   path: '/betatests',
        //   meta: {
        //     title: 'Betatests',
        //     crumbName: 'Betatests',
        //   },
        //   component: () => import('./views/betatests'),
        //   children: [
        //     {
        //       path: '/',
        //       redirect: 'beta',
        //     },
        //     {
        //       path: 'beta',
        //       meta: {
        //         title: 'Betatests',
        //       },
        //       component: () => import('./views/betatests/beta'),
        //     },
        //     {
        //       path: 'beta/:betatestId/comments',
        //       meta: {
        //         title: 'Betatest comments',
        //         crumbName: 'Betatest comments',
        //       },
        //       component: () => import('./views/betatests/beta/comments'),
        //     },
        //     {
        //       path: 'users',
        //       meta: {
        //         title: 'Betatests users',
        //       },
        //       component: () => import('./views/betatests/users'),
        //     },
        //   ],
        // },
      ],
    },
    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        // {
        //   path: '/auth/404',
        //   meta: {
        //     title: 'Error 404',
        //   },
        //   component: () => import('./views/auth/404'),
        // },
        // {
        //   path: '/auth/500',
        //   meta: {
        //     title: 'Error 500',
        //   },
        //   component: () => import('./views/auth/500'),
        // },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        // {
        //   path: '/auth/lockscreen',
        //   meta: {
        //     title: 'Lockscreen',
        //   },
        //   component: () => import('./views/auth/lockscreen'),
        // },
      ],
    },

    // Redirect to 404
    {
      path: '/:event/:page',
      component: () => import('./views/dinamic-page'),
    },
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    const token = localStorage.getItem('accessToken')
    if (!token) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
/* eslint-enable */

export default router
